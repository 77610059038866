<template>
  <div class="login-page-wrapper">
    <login-dialog class="login-comp"></login-dialog>
  </div>
</template>

<script>

  import LoginDialog from "@/page-home/components/Login/index";
  export default {
    name: 'Login',
    components: {LoginDialog},
    data() {
      return {
      }
    },

    methods: {

    },

    created() {

    }
  }
</script>

<style lang="scss" scoped>
  .login-page-wrapper {
    height: 100%;
    background-color: #1D2B5A;
    overflow: hidden;
    .login-comp {
      margin: 180px auto 0;
    }
  }
</style>
